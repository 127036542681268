<template>
    <div>
        <div class="modal fade" id="BorrowingToolsCreateModal" tabindex="-1" aria-labelledby="BorrowingToolsCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="BorrowingToolsCreateModalLabel" class="font-weight-bold">Pilih Project untuk Peminjaman Alat</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :columns="columns"
                            :resizable="true"
                        >
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { globalfunc } from '../../../../shared/GlobalFunction';
import borrowingToolsService from '../Script/BorrowingToolsServices.js';

export default {
    name: 'BorrowingToolsCreateForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem.project_id);
        })
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> </div>'
                },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_code", title: "Kode Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Keterangan Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_form_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize, Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                projecttype : 1,
                                status : ['Ready', 'Finish']
                            }
                            return { 
                                query: borrowingToolsService.GetProjectDataQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionProject.project == null){
                            return [];
                        }else{
                            return response.data.GetTransactionProject.project;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionProject.project == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionProject.total;
                        }
                    },
                },
            });
            
            window.$('#BorrowingToolsCreateModal').modal('show');
        },
        createDetailClick(data){
            window.$('#BorrowingToolsCreateModal').modal('hide');
            this.$router.push({ name: 'Borrowing Tools Form', params: {  formtype:'Add', id:data, view:'new' } })
        },
    }
}
</script>

<style scoped>
    .modal-xl-custom {
        max-width: 1500px;
    }
</style>