<template>
    <div>
        <div class="modal fade" id="BorrowingToolsStatusModal" tabindex="-1" aria-labelledby="BorrowingToolsStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="BorrowingToolsStatusModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

import borrowingToolsServices from '../Script/BorrowingToolsServices.js';

export default {
    name: 'BorrowingToolsStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(id, status){
            this.ModalTitle = 'Edit Status Peminjaman Alat: ';
            this.Id = id;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_borrowing_tools');
            this.Status = status;
            
            window.$('#BorrowingToolsStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await borrowingToolsServices.GetProjectBorrowedToolDetail(this.Id);            
            var itemDetailData = globalfunc.objectToArrayConverter(data.detail, "BorrowingTools-ItemDetailSave");
            
            const borrowingTools = {
                project_id: data.project_id,
                storage_id: data.storage_id,
                notes: data.notes,
                status: this.Status,
                detail: itemDetailData
            }
            
            const variables = {
                id: parseInt(this.Id),
                data : borrowingTools
            }
            
            borrowingToolsServices.editQuery(variables).then(res => {
                this.$loading(false);
                window.$('#BorrowingToolsStatusModal').modal('hide');
                this.$swal("Info", response.successUpdate, "success");
                this.$props.reload();
            }).catch(error => {
                this.$loading(false);
                var errorMessage = globalfunc.ErrorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            })
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>