<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="pageableConfig"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :columns="columns"
                    :resizable="true"
                    v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import borrowingToolsService from '../Script/BorrowingToolsServices.js';

export default {
    name: 'BorrowingToolsGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'statusClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: borrowingToolsService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedTool.total;
                        }
                    },
                    model: {
                        fields: {
                            limit: {type: "number"},
                            invoiced_projects: {type: "number"},
                            invoiced_payment_projects: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: this.columnButton
                },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                },
                { field: "project_code", title: "Kode Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "limit", title: "Nilai PJT", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"} },
                { field: "invoiced_projects", title: "Nilai Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"} },
                { field: "invoiced_payment_projects", title: "Nilai Invoice Terbayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"} },
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "storage_name", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.project_borrowed_tool_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.project_borrowed_tool_id, true);
        })

        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            statusClick(dataItem.project_borrowed_tool_id, dataItem.status);
        })
    },
    methods: {
        columnButton(e){
            return this.$globalfunc.gridActionStatusButtonWithoutDelete("Peminjaman Alat")
        },
        detailInit: async function (e) {
            var data = await borrowingToolsService.GetProjectBorrowedToolDetail(e.data.project_borrowed_tool_id);

            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "BorrowingToolsDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'status', title: "Status", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'uom', title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "quantity", title: "Qty", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: 'notes', title: "Catatan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'created_by', title: "Dibuat Oleh", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'created_at', title: "Tgl Dibuat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                    },
                ],
            });
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: borrowingToolsService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionProjectBorrowedTool.project_borrowed_tool == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedTool.total;
                        }
                    },
                    model: {
                        fields: {
                            limit: {type: "number"},
                            invoiced_projects: {type: "number"},
                            invoiced_payment_projects: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        }
    }
}
</script>

<style scoped>
</style>